.card-qrcode-invite {
    width: 70%;
    height: 70%;
    border-radius: 10px;
    /* background-image: url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/85e832ef-4584-4740-8f1a-1cbc865b00f0.png");
    background-size: cover; */
    background-color: #171717a8;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.cloud-one {
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 300px;
    background-image: url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/707649e9-f8b0-4d01-81c8-6c0c02ef3706.png");
    background-size: cover;
    z-index: 2;
    border-radius: 10px;
    animation: animationCloudOne 4s infinite alternate-reverse;
}

.cloud-two {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 700px;
    height: 300px;
    background-image: url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/b7181a48-daad-4676-922d-eb470e04334a.png");
    background-size: cover;
    border-radius: 10px;
    animation: animationCloudTwo 10s infinite alternate-reverse;
}

.cloud-three {
    position: absolute;
    bottom: -100px;
    right: -400px;
    width: 600px;
    height: 300px;
    background-image: url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/b7181a48-daad-4676-922d-eb470e04334a.png");
    background-size: contain;
    z-index: 1;
    border-radius: 10px;
    animation: animationCloudThree 6s infinite alternate-reverse;
}

.cloud-four {
    position: absolute;
    top: 0;
    left: -100px;
    width: 600px;
    height: 300px;
    background-image: url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/707649e9-f8b0-4d01-81c8-6c0c02ef3706.png");
    background-size: cover;
    z-index: 1;
    border-radius: 10px;
    animation: animationCloudFour 6s infinite alternate-reverse;
}

@keyframes animationCloudOne {
    0% {
        right: 0px;
    }

    100% {
        right: 200px;
    }
}

@keyframes animationCloudTwo {
    0% {
        left: 0px;
    }

    100% {
        left: 100px;
    }
    
}

@keyframes animationCloudThree {
    0% {
        right: -400px;
    }

    100% {
        right: 0px;
    }
    
}

@keyframes animationCloudFour {
    0% {
        left: -100px;
    }

    100% {
        left: 0px;
    }
    
}

.smartphone-cloud {
    width: 280px;
    height: 260px;
    object-fit: cover;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.card-qrcode-invite-children {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}