.upload-file {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border-style: dashed;
    border-width: 2px;
    border-color: #c4c4c4;
    height: 200px;
    overflow: hidden;
}

.button-upload-file{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 40px;
    cursor: pointer;
    height: 150px;
    border-width: 0px;
}

.upload-file-files {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border-style: dashed;
    border-width: 2px;
    border-color: #c4c4c4;
    height: 500px;
    overflow: hidden;
}