.div-vertical-menu {
    height: 64px;
    object-fit: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-vertical-menu {
    height: 60px;
    width: 60px;
    object-fit: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-base-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}