* {
  margin: 0px;
  padding: 0px;
}


.input {
  width: 90%;
  background: rgba(167, 136, 234, 0.41);
  border-radius: 6px;
  padding-left: 2%;
  font-size: 14;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  height: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: white;
}

input::placeholder {
  color: white;
}


.button-send {
  width: 15%;
  height: 30px;
  background: rgba(167, 136, 234, 0.41);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: white;
  cursor: pointer;
}

.button-send:hover {
  background: white;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: #A788EA;
  cursor: pointer;
}

.input-new-comment {
  width: 60%;
  background: rgba(167, 136, 234, 0.41);
  border-radius: 6px;
  padding-left: 2%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  height: 32px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: white;
}

.button-send-new {
  width: 32px;
  height: 32px;
  padding: 2%;
  background: rgba(167, 136, 234, 0.41);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: white;
  cursor: pointer;
}

.button-send-new:hover {
  background: white;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(167, 136, 234, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #A788EA;
  color: #A788EA;
  cursor: pointer;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scroll-container {
  scrollbar-width: auto;
  scrollbar-color: #59399D #100f0f;
}
